import React from 'react'
import { Link } from 'gatsby'

import { Layout } from '../components/layout'
import SEO from '../components/seo'
import Scrollspy from 'react-scrollspy'

import IntroInfoVis from '../components/info_vis/intro_infovis'
import { Row, Container } from 'reactstrap'

import Footer from './footer'
import { GoogleInternIntro } from '../components/googleintern/google_intro'
import { GoogleInternOverview } from '../components/googleintern/google_overview'
import { GoogleInternLesson } from '../components/googleintern/google_lessons'
import { GoogleSectionSeperator } from '../components/googleintern/google_common_component'
import { getSeperatorWithHeight } from "../components/text_style"


const GoogleIntern = () => {
  return (
    <Layout>
      <SEO title="Google UXE Intern" />
      {getSeperatorWithHeight(50)}
      <Container style={{marginLeft:"0px", marginRight: "0px", maxWidth: "1400px", alignSelf:"center"}}>
        <GoogleInternIntro />

        <GoogleSectionSeperator />

        <GoogleInternOverview />

        <GoogleSectionSeperator />

        <GoogleInternLesson />

        {getSeperatorWithHeight(80)}
        <Row className="d-flex justify-content-center">
          <a style={{ color: `#ADADAD` }} href="#">
            Back to top
          </a>
        </Row>

        {getSeperatorWithHeight(20)}
        <Footer />
      </Container>
    </Layout>
  )
}

export default GoogleIntern
