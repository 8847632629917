import React from 'react'
import {Row, Col, Container} from "reactstrap"
import {GoogleInternH1, Subtitle1, H4, gutter, BodyText1, paddingOneSide} from "./google_style"
// import screenShowOffNew from '../../images/bpresentv2/screen-show-off-new.png'
import chromeOS from '../../images/googleintern/chrome-os.png'

export const GoogleInternIntro = (props) => {

    return (
        <Row>
            {/* Need to find a better solution to this look */}
            <Col sm={{size: 10, offset:1}} style={{display:"flex", flexDirection:"column", paddingLeft: `${paddingOneSide}px`, paddingRight: `${paddingOneSide}px`}}>
                <GoogleInternH1>
                    Summer 2019 Intern at Google 
                </GoogleInternH1>
                
                <Subtitle1 style={{marginTop:"16px", fontWeight: 300}}>
                    As a UX Engineer at Chrome OS
                </Subtitle1>

                <Subtitle1 style={{marginTop:"8px", fontWeight: 300}}>
                    May. 2019 - Aug. 2019
                </Subtitle1>

                <H4 style={{marginTop: "2rem", marginBottom:"2rem"}}>
                    Prototype and design for tablet interaction on Chrome OS within a cross functional team
                </H4>

                {/* Put the image here */}
                <img src={chromeOS} style={{width:"50%", alignSelf:"center", marginTop:"60px"}} >
                </img>
            
            </Col>    

        </Row>
    )
}


