import React from 'react'
import {Row, Col, Container} from "reactstrap"
import {GoogleInternH1, Subtitle1, H4, gutter, BodyText1, paddingOneSide, styleForColumn} from "./google_style"
import screenShowOffNew from '../../images/bpresentv2/screen-show-off-new.png'
import { useInView } from 'react-intersection-observer'

export const GoogleInternLesson = (props) => {

    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0,
        // root:""
    })

    return (
        <React.Fragment>
            <Row style={{
                    position: "sticky" , top:"40px", zIndex:3, background:"transparent", 
                    transition: "opacity 0.2s ease-in", opacity: inView? 1 : 0
                }}>
                <Col md={{size: 4, offset: 1}} style={styleForColumn}>
                    <H4>
                        What I learnt
                    </H4>
                </Col>
            </Row>

            <Row style={{marginTop: "1.5rem", position:"relative"}}>
                <Col md={{size: 6, offset: 5}} style={styleForColumn}>
                    <BodyText1>
                    The job title is a perfect fit for me, blending coding with design and research. It helped me grow as a better interaction designer and taught me the essential process and ingrediants of creating a product with great user experience. First, it gave me time and chance to <strong> enjoy crafting interactions </strong> and animations. After the hands-on experience, I had a better understanding of the <strong> importance of prototypes </strong> in the design process and how I should build them to inform the design decisions. At the end, I also learnt how to become <strong>an effective UX team member</strong>, collaborating with other key members and taking initiative to own and push the project. After all, communicating between designers and engineers is essential to the role of UX Engineer. 
                    </BodyText1>
                </Col>

                <div style={{height:"70%", width: "10px", background:"transparent", position:"absolute", top: "-20px"}} ref={ref}/>
            </Row>
            
        </React.Fragment>
    )
}