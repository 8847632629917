import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'
import researchImage from '../../images/hci_foundation/Screen-show-off.png'
import { StyledContainer } from '../layout'
import YouTube from 'react-youtube';
import {Title, Acknowledgement, Brief, IntroLine, IntroSubHeader, ImageCaption} from './infovis_style'

let StyledBrief = styled(Col)`

  font-family: OpenSans-Regular;
  font-size: 24px;
  color: #161616;
  letter-spacing: -0.5px;
  line-height: 35px;
`



class IntroInfoVis extends React.Component{


  render(){
    if (typeof window !== `undefined`) {
      window.YTConfig = {
        host: 'https://www.youtube.com'
      }
    }

    return (
      <StyledContainer fluid={true}>

        <Row>
          <Col md={3}> </Col>
          <Col xs={6}>
            <Title> The Impatient List </Title> </Col>
          <Col xs={3}/>
        </Row>

        <Row>
          <Col md={3}> </Col>
          <Col md={6}> <Acknowledgement>  Collaborated with Tony Jin, Kuhu Gupta, Het Sheth </Acknowledgement> </Col>
          <Col md={3}/>
        </Row>

        <Row style={{paddingTop: 48, paddingBottom: 48}}>
          <Col md = {3}> </Col>
          <Col md={6}>
            <Brief>
              Information visualization that calls general public’s attention to the waiting list and patients of the kidney transplant in United States.
            </Brief>
          </Col>
          <Col md={3}/>
        </Row>

        <Row>
          <Col md={3}/>
          <Col md={3}>
            <IntroSubHeader style={{paddingBottom:12}}>My Role:</IntroSubHeader>
            <IntroLine>Research lead </IntroLine>
            <IntroLine>Ideation lead</IntroLine>
            <IntroLine>Lead design and development for the animated vis </IntroLine>
          </Col>

          <Col md={3}>
            <IntroSubHeader style={{paddingBottom:12}}> Time: </IntroSubHeader>

            <IntroLine> Aug. - Dec. 2018 </IntroLine>
          </Col>
          <Col md={3}/>
        </Row>

        <Row style={{paddingTop:40}}>
          <Col md={3}/>
          <Col md={6} className="d-flex justify-content-center">
            <YouTube style={{width:`100%`}} videoId="OKFQjO0MUm4" />
          </Col>
        </Row>

        <Row>
          <Col md={3}>
          </Col>

          <Col md={6} className="d-flex justify-content-center">
            <ImageCaption style={{fontSize:"16px"}}>
              Check our demo: <a href={"http://xujunjiejack.github.io"}> xujunjiejack.github.io </a>
            </ImageCaption>
          </Col>
        </Row>
      </StyledContainer>
    )

  }
}

export default IntroInfoVis