import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'
import {displayFont, displayFontWeight, textFont, textFontWeight}  from '../site_style'

import {H1, H2, H3, H4, Subtitle1, Subtitle1OpenSans, Subtitle2, Subtitle2OpenSans, BodyText1, BodyText2, Caption, Subtitle1OpenSansWithLightColor}  from '../text_style'

const mainColor = "#4285F4"

const GoogleInternH1 = styled(H1)`
    color:${mainColor};
    letter-spacing: -1px;
    font-size: 3.25rem;
` 

const marginVertical = 50
const gutter = 44
const paddingOneSide = 22

const styleForColumn = 
    {display:"flex", flexDirection:"column", paddingLeft: `${paddingOneSide}px`, paddingRight: `${paddingOneSide}px`}

export {mainColor, GoogleInternH1, marginVertical, Subtitle1, H4, gutter, BodyText1, BodyText2, paddingOneSide, styleForColumn, Caption, Subtitle1OpenSansWithLightColor}

