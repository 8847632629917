import React from 'react'
import {Row, Col, Container} from "reactstrap"
import {GoogleInternH1, Subtitle1, H4, gutter, BodyText1, paddingOneSide, styleForColumn} from "./google_style"
import screenShowOffNew from '../../images/bpresentv2/screen-show-off-new.png'
import internPhoto from "../../images/googleintern/intern-gift.jpg"
import { useInView } from 'react-intersection-observer'
import uxSpectrum from "../../images/googleintern/UXEng.png";

export const GoogleInternOverview = (props) => {
    
    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0,
        // root:""
    })
    
    return (
        <React.Fragment>
            <Row style={{
                    position: "sticky" , top:"40px", zIndex:3, background:"transparent", 
                    transition: "opacity 0.2s ease-in", opacity: inView? 1 : 0
                }}>
                <Col md={{size: 4, offset: 1}} style={styleForColumn}>
                   <H4>
                    Overview
                    </H4>
                </Col>
            </Row>

            <Row style={{position:"relative"}}>
                <Col md={{size: 4, offset: 7}}>
                    <img src={internPhoto} style={{borderRadius:"8px", marginBottom:"3rem"}}/>
                </Col>

                <Col md={1}></Col>

                <Col md={{size: 6, offset: 5}} style={styleForColumn}>
                    
                    <BodyText1 style={{marginBottom:"2rem"}}>
                        Last summer, I interned at Google as a <strong> UX Engineer in Chrome OS team </strong> in Mountain View, California for 12 weeks. Due to my interest in interaction design, my host and I chose projects collaborating closely with designers. Each prototype ended up a functional, high-fidelity one, and each was used to conduct multiple user testings and demo to leadership for further implementation. I worked closely with a global team of interaction designers, researchers, program managers, and engineers to turn great ideas into tangeble prototypes, with polished details for a great user experience. 
                    </BodyText1>

                    <BodyText1 style={{marginBottom:"2rem"}}>
                        Throughout the course of three months, I eventually prototyped and designed <strong> two projects for tablet-based interaction features </strong>. One is a feature for the better virtual keyboard experience while the other relates to the system gesture on Chrome OS. For those projects, I not only coded with framer.js heavily to build functional prototypes but also executed my sense of design to give recommendations on details of interactions such as layout, animations and visual designs. I also planned with a full usability testing session, and moderated it with designers and researchers.   
                    </BodyText1>

                    <BodyText1 style={{marginBottom:"2rem"}}>
                        Both prototyped features have received commitment from engineers to implement and is currently undergoing. If you are a geek, you can even find their traces in the open source code online :). 
                    </BodyText1>

                    <BodyText1 style={{marginBottom:"2rem"}}>
                        Under the request from my managers, I won’t be able to  disclose more details related to those features until they are implemented in the later Chrome OS update. It comes soon and stay tune!  But I can share some learnings. 
                    </BodyText1>
                </Col>

                <Col md={1}></Col>        

                <Col md={{size: 10, offset: 1}} style={styleForColumn}>
                    <img src={uxSpectrum} style={{alignSelf:"center", width:"80%"}}></img>
                </Col>

                <div style={{height:"70%", width: "10px", background:"transparent", position:"absolute", top: "-20px"}} ref={ref}/>
            </Row>
            
        </React.Fragment>
    )
}